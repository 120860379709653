var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "van-main" }, [
        _c(
          "div",
          { staticClass: "van-common-detail" },
          [
            _c(
              "van-cell-group",
              [
                _c("van-cell", {
                  attrs: {
                    title: "项目管理",
                    "is-link": "",
                    to: "/project/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "合同管理",
                    "is-link": "",
                    to: "/contract/list"
                  }
                }),
                _c("van-cell", {
                  attrs: { title: "订单管理", "is-link": "", to: "/order/list" }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "提单管理",
                    "is-link": "",
                    to: "/billofloading/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "批文管理",
                    "is-link": "",
                    to: "/license/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "仓储管理",
                    "is-link": "",
                    to: "/storage/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "收款管理",
                    "is-link": "",
                    to: "/payment/collection/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "付款管理",
                    "is-link": "",
                    to: "/payment/management/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "发票管理",
                    "is-link": "",
                    to: "/invoice/platformList"
                  }
                }),
                _c("van-cell", {
                  attrs: { title: "资金管理", "is-link": "", to: "/fund/menu" }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "风控管理",
                    "is-link": "",
                    to: "/intelligence/list"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("tab-bar", { attrs: { active: "service" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }