<template>
  <div>
    <div class="van-main">
      <div class="van-common-detail">
        <van-cell-group>
          <van-cell title="项目管理" is-link to="/project/list" />
          <van-cell title="合同管理" is-link to="/contract/list" />
          <van-cell title="订单管理" is-link to="/order/list" />
          <van-cell title="提单管理" is-link to="/billofloading/list" />
          <van-cell title="批文管理" is-link to="/license/list" />
          <van-cell title="仓储管理" is-link to="/storage/list" />
          <van-cell title="收款管理" is-link to="/payment/collection/list"/>
          <van-cell title="付款管理" is-link to="/payment/management/list"/>
          <van-cell title="发票管理" is-link to="/invoice/platformList" />
          <van-cell title="资金管理" is-link to="/fund/menu" />
          <van-cell title="风控管理" is-link to="/intelligence/list" />
        </van-cell-group>
      </div>
    </div>
    <tab-bar active="service" />
  </div>
</template>

<script>
import TabBar from '@/components/tab-bar'
import { Cell, CellGroup } from 'vant'
export default {
  components: {
    TabBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
